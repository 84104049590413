
<template>
  <div>
    <admin-title></admin-title>

    <div class="container">
      <div class="page-title">
        <span>説明会管理</span>
      </div>


      <div v-show="type==1" class="add-info">
        <p class="title" v-if="id">説明会登録</p>
        <p class="title" v-else>説明会登録（新規作成）</p>

        <div class="add-form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="12.422rem" class="demo-ruleForm">

            <span class="right-btn" @click="back()">一覧へ戻る</span>

            <el-form-item label="企業名" >
              <el-input style="border:0;" placeholder="WinC Career株式会社（デモアカウント）" v-model="compName" readonly></el-input>
            </el-form-item>

            <el-form-item label="説明会タイトル" prop="seminarTitle" >
              <el-input placeholder="【24卒】6月度◆オンライン会社説明会◆" maxlength="50" v-model="ruleForm.seminarTitle"></el-input>
            </el-form-item>


            <el-form-item label="対象年度" prop="forTheYear" >
              <el-select placeholder="" maxlength="50" ref='selectLable' v-model="ruleForm.forTheYear" :disabled="$route.query.id?true:false">
                <el-option
                    v-for="item in forTheYearList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>



            <el-form-item class="hold-time" label="開催日時" required>
              <el-col :span="7">
                <el-form-item prop="startDate">
                  <el-date-picker
                      v-model="ruleForm.startDate"
                      type="datetime"
                      style="width: 100%;"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      placeholder="">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!--              <el-col class="line" :span="1"></el-col>-->
              <el-col :span="7">
                <el-form-item prop="endDate" >
                  <el-date-picker
                      v-model="ruleForm.endDate"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      style="width: 100%;"
                      placeholder="">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>


            <el-form-item label="説明会詳細" class="has-tip" prop="seminarDetail" >
              <div class="right-tip" @click="placeholderHhandel(1)">テンプレを入れる</div>
              <el-input :rows="8" :placeholder="descPlaceholder" maxlength="1000" type="textarea" v-model="ruleForm.seminarDetail">


              </el-input>

            </el-form-item>

            <el-form-item label="開催場所詳細" prop="venue" >
              <el-input placeholder="WinC Career株式会社　東京本社" maxlength="100" v-model="ruleForm.venue"></el-input>
            </el-form-item>

            <el-form-item label="備考" prop="remarks">
              <el-input :rows="8" maxlength="1000" placeholder="ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。" type="textarea" v-model="ruleForm.remarks"></el-input>
            </el-form-item>

            <div class="right-tip" @click="placeholderHhandel(2)" style="margin-left: 44.4rem;">テンプレを入れる</div>
            <el-form-item label="受付タイトル" prop="appTitle" >
              <el-input maxlength="200" :placeholder="placeholderAppTitle" v-model="ruleForm.appTitle"></el-input>
            </el-form-item>

            <el-form-item class="has-tip" label="受付内容" prop="appDetails" >
              <div class="right-tip" @click="placeholderHhandel(3)">テンプレを入れる</div>
              <el-input :rows="8" maxlength="1000" :placeholder="appDetailsPlaceholder" type="textarea" v-model="ruleForm.appDetails"></el-input>
            </el-form-item>

            <div class="right-tip" @click="placeholderHhandel(4)" style="margin-left: 44.4rem;">テンプレを入れる</div>
            <el-form-item label="受付窓口" prop="appContact">
              <el-input maxlength="1000" v-model="ruleForm.appContact" :placeholder="placeholderAppContact"></el-input>
            </el-form-item>

            <el-form-item label="受付電話番号" prop="appContactInformation" >
              <el-input placeholder="03-0000-0000" v-model="ruleForm.appContactInformation"></el-input>
            </el-form-item>

            <el-form-item label="受付メールアドレス" prop="appContactEmail" >
              <el-input placeholder="test@winc.com" maxlength="319" v-model="ruleForm.appContactEmail"></el-input>
            </el-form-item>

            <div class="right-tip" @click="placeholderHhandel(5)" style="margin-left: 44.4rem;">テンプレを入れる</div>
            <el-form-item label="受付連絡時間" prop="appContactTime">
              <el-input v-model="ruleForm.appContactTime" :placeholder="placeholderAppContactTime"></el-input>
            </el-form-item>

            <el-form-item label="表示順" prop="sort">
              <el-input-number style="width:39.062rem;" placeholder="" controls-position="right" :min="1" :max="100" v-model="ruleForm.sort"></el-input-number>
            </el-form-item>

            <el-form-item label="公開予約日時" required>
              <el-form-item prop="publicReservationDatetime">
                <el-date-picker
                    v-model="ruleForm.publicReservationDatetime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                    placeholder="">
                </el-date-picker>
              </el-form-item>
            </el-form-item>

            <el-form-item label="公開終了予約日時" required>
              <el-form-item prop="publicReservationDatetimeEnd">
                <el-date-picker
                    v-model="ruleForm.publicReservationDatetimeEnd"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                    placeholder="">
                </el-date-picker>
              </el-form-item>
            </el-form-item>




            <div class="form-button">
              <div class="yellow-btn"  @click="submitForm('ruleForm')">保存</div>
              <div class="view-btn" @click="previewDetail()">プレビュー</div>
              <div class="green-btn" @click="closeForm()">一覧へ戻る</div>
            </div>
            <!--            <el-form-item>-->
            <!--              <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
            <!--              <el-button @click="resetForm('ruleForm')">重置</el-button>-->
            <!--            </el-form-item>-->
          </el-form>
        </div>
      </div>


      <explain-meeting-detail @changeType="changeType" ref="meetingDetail" v-show="type == 3"></explain-meeting-detail>


    </div>
  </div>
</template>


<script>

import explainMeetingDetail from "@/components/pageComponents/explainMeetingDetail";

export default {
  name: "explainMeetingAdd",
  components: {
    explainMeetingDetail
  },
  prop:{
    data:Object
  },
  data() {

    var validateEmail = (rule, value, callback) => {
      if (/[\uff00-\uffff]/g.test(value)) {
        callback(new Error('受付メールアドレス」が正しくありません'));
      } else {
        callback();
      }
    };

    var startDate = (rule, value, callback) => {
      if (value > this.ruleForm.endDate) {
        callback(new Error('開催日時の終了日時は、開始日時よりも後の日時を設定してください'));
      } else {
        callback();
      }
    };

    var endDate = (rule, value, callback) => {
      if (value <= this.ruleForm.startDate) {
        callback(new Error('開催日時の終了日時は、開始日時よりも後の日時を設定してください'));
      } else {
        callback();
      }
    };

    return {
      id:0,
      type:1,
      forTheYearList:[],
      compName:"",
      descPlaceholder:"●年卒採用について説明会を実施します!!\n" +
          "【募集職種はコチラ】\n" +
          "■●●職\n" +
          "■●●職\n" +
          "\n" +
          "まずは説明会にご参加ください！\n" +
          "\n" +
          "【当日のスケジュール】\n" +
          "1.当社について\n" +
          "2.社長参加の座談会\n" +
          "恒例の座談会を開催いたします◎\n" +
          "3.一次選考\n" +
          "興味を持っていただけたら、そのまま一次選考を実施します!!\n" +
          "一次選考は●●を予定しております◎\n" +
          "\n" +
          "【服装】\n" +
          "私服 ※スーツでも構いません\n" +
          "\n" +
          "【開催方法】\n" +
          "Zoomでのオンライン説明会とさせていただきます。\n" +
          "詳しくは追ってご連絡をさせていただきます。",
      placeholderAppTitle: "説明会へのエントリーありがとうございます!!",
      appDetailsPlaceholder:"この度は当社説明会へのエントリーありがとうございます。\n" +
          "2営業日中にメールまたは電話にて連絡いたします。\n" +
          "今しばらくお待ちください。\n" +
          "\n" +
          "※送信後、2営業日以上たっても連絡がない場合はエラーの可能性がございますので、大変お手数ですが以下のメールアドレスまでお問い合わせください。",
      placeholderAppContact: "採用担当",
      placeholderAppContactTime: "10:00-19:00",
      ruleForm: {
        seminarTitle:"",
        startDate:"",
        endDate:"",
        seminarDetail:"",
        venue:"",
        remarks:"",
        appTitle:"",
        appDetails:"",
        appContactInformation:"",
        appContact:"",
        appContactEmail:"",
        appContactTime:"",
        publicReservationDatetime:"",
        publicReservationDatetimeEnd:"",
        sort:""

      },
      rules: {
        seminarTitle: [
          { required: true, message: '「説明会タイトル」が入力されていません', trigger: 'blur' }
        ],
        forTheYear: [
          { required: true, message: '「対象年度」が入力されていません', trigger: 'blur' }
        ],

        startDate: [
          { required: true, message: '「開催日時」が設定されていません', trigger: 'blur' },
          //{ validator: startDate, trigger: 'blur' },
        ],
        endDate: [
          { required: true, message: '「開催日時」が設定されていません', trigger: 'blur' },
          { validator: endDate, trigger: 'blur' },
        ],
        seminarDetail: [
          { required: true, message: '「説明会詳細」が入力されていません', trigger: 'blur' }
        ],
        venue: [
          { required: true, message: '「開催場所」が入力されていません', trigger: 'blur' }
        ],
        appTitle: [
          { required: true, message: '「受付タイトル」が入力されていません', trigger: 'blur' }
        ],
        appDetails: [
          { required: true, message: '「受付内容」が入力されていません', trigger: 'blur' }
        ],
        appContact: [
          { required: true, message: '「受付窓口」が入力されていません', trigger: 'blur' }
        ],
        appContactInformation: [
          { required: true, message: '「受付電話番号」が入力されていません', trigger: 'blur' },
          {pattern: '^[0-9-]*$', message: '「受付電話番号」が正しくありません', trigger: 'blur'}
        ],
        appContactEmail: [
          { required: true, message: '「受付メールアドレス」が入力されていません', trigger: 'blur' },
          //{pattern: '/[\uff00-\uffff]/g', message: '受付メールアドレス」が正しくありません', trigger: 'blur'},
          { validator: validateEmail, trigger: 'blur' },
          {type: "email",message: "「受付メールアドレス」が正しくありません",trigger: ["blur", "change"]}
        ],
        appContactTime: [
          { required: true, message: '「受付連絡時間」が入力されていません', trigger: 'blur' }
        ],
        publicReservationDatetime: [
          { required: true, message: '「公開予約日時」が設定されていません', trigger: 'blur' }
        ],
        publicReservationDatetimeEnd: [
          { required: true, message: '「公開終了予約日時」が設定されていません', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '「表示順」が入力されていません', trigger: 'blur' }
        ],

      }
    }
  },
  mounted() {

    let USER_INFO=JSON.parse(localStorage.getItem("USER_INFO"));
    this.compName=USER_INFO.compName;
    this.ruleForm.compId=parseInt(USER_INFO.compId);
    this.ruleForm.operatorId=parseInt(USER_INFO.id);

    this.id=this.$route.query.id;
    this.initData();

  },
  methods: {
    back(){
      this.$router.back();
    },
    previewDetail(){
      this.changeType(3);
      this.ruleForm.forTheYearText=this.$refs.selectLable.selected.label;
      console.log("预览",this.ruleForm.forTheYearText);
      this.$refs['meetingDetail'].initData(this.ruleForm);
    },

    async initData(){



      let data = await this.$axios({url: `/common/getMasterListByName/卒業年度`, data: {}, method: "get"});

      this.forTheYearList=data.data;


       if(this.$route.query.id){
         let res = await this.$axios({url: `/seminar/detail`, data:{id:this.$route.query.id}
           , method: "post"});
         this.ruleForm=res.data;
       }
      else if(this.$route.query.cid){
        let res = await this.$axios({url: `/seminar/detail`, data:{id:this.$route.query.cid}
          , method: "post"});
        this.ruleForm=res.data;
        delete this.ruleForm.id;
      }
      else{
        this.ruleForm.compName=this.compName;
       }
    },
    closeForm(){
      this.$router.go(-1);
    },
    placeholderHhandel(type){
      if(type==1){
        this.ruleForm.seminarDetail=this.descPlaceholder;
      } else if (type==2) {
        this.ruleForm.appTitle=this.placeholderAppTitle;
      } else if (type==3) {
        this.ruleForm.appDetails=this.appDetailsPlaceholder;
      } else if (type==4) {
        this.ruleForm.appContact=this.placeholderAppContact;
      } else if (type==5) {
        this.ruleForm.appContactTime=this.placeholderAppContactTime;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.submitFormHandel();

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async submitFormHandel() {
      if(this.ruleForm.id){
        let res = await this.$axios({url: `/seminar/update`, data:this.ruleForm
          , method: "post"});
        if(res.code == '000') {
          this.$message(res.message);

          //this.$router.push('explainMeeting');


        }
      }
      else{
        let res = await this.$axios({url: `/seminar/add`, data:this.ruleForm
          , method: "post"});
        if(res.code == '000') {
          this.$message(res.message);
          //this.$router.push('explainMeeting');

        }
      }

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeType(type) {
      this.type = type;
    }


  }
}
</script>

<style scoped src="../../style/explainMeeting.css">

</style>

<style scoped>

.right-btn{
border:1px solid #424242;position: absolute;right:1.6rem;top:1.8rem;
  border-radius: 10rem;z-index:10;cursor: pointer;font-size:0.9375rem;
  width:7.8125rem;text-align: center;display: inline-block;height:1.875rem;line-height:1.875rem;

}

.right-btn:hover{
  background: #3CB55E;color:#fff;border-color: #3CB55E;
}

.add-info {
  margin-top: 3.125rem;
}

.add-form >>> .has-tip label{padding-top:1rem;}

.right-tip{margin-left:32rem;color:#2876F6;font-size:0.78rem;padding:0.3rem 0;cursor: pointer}

.add-info .title {

  font-style: normal;
  font-weight: 600;
  font-size: .9375rem;
  line-height: 1.1rem;

  color: #424242;

  margin: 3.125rem 0 1.4rem 0;
}

.add-info .add-form {
  width: 100%;
  /*height: 20vh;*/
  background: #FFFFFF;
  box-shadow: 2.34375px 2.34375px 0px rgba(0, 0, 0, 0.05);
  border-radius: .625rem;
  position: relative;
  padding-top: 1.875rem;
  padding-bottom: 5rem;
}

.add-info .add-form /deep/ .el-form-item__label {
  padding-right: 1.153rem;


  font-style: normal;
  font-weight: 600;
  font-size: 1.09375rem;
  line-height: 3.906rem;
}

.add-info .add-form /deep/ .el-input__inner,
.add-info .add-form /deep/ .el-textarea__inner{
  width: 39.062rem;
  height: 3.906rem;

  background: #FFFFFF;
  border: 0.585938px solid #CCCCCC;
  box-shadow: inset 0.78125px 0.78125px 3.90625px rgba(0, 0, 0, 0.23);
  border-radius: .390625rem;

  font-size: 1.09375rem;
  color: black;
}

.add-info .add-form /deep/ .el-input__inner::placeholder,
.add-info .add-form /deep/ .el-textarea__inner::placeholder{

  font-style: normal;
  font-weight: 400;
  font-size: 1.09375rem;
  line-height: 1.3rem;

  color: #A3A3A3;
}

.add-info .add-form /deep/ .hold-time .el-input__inner {
  width: 17.186rem;
}

/*.add-info .add-form /deep/ .hold-time .el-clo*/

/*.el-form-item__label*/


.add-info .add-form /deep/ .el-textarea__inner {
  height: auto;
}

.add-info .add-form /deep/ .el-icon-time:before {
  display: inline-block;
  /*margin-top: .26rem;*/


  line-height: 3.906rem;
  /* width: 7rem; */
  /* height: 1.172rem; */
  font-size: 1.094rem;
}

.add-info .add-form /deep/ .el-form-item__content {
  line-height: normal;
}

.add-info .add-form /deep/ .el-textarea__inner {
  padding: 1.25rem;
}

.add-info .add-form /deep/ .el-input-number .el-input__inner{
  text-align: left;
}

.add-info .add-form /deep/ .el-input-number.is-controls-right .el-input-number__increase, .add-info .add-form /deep/ .el-input-number.is-controls-right .el-input-number__decrease{
  line-height:2rem;
}

.add-info .add-form /deep/ .el-input-number.is-controls-right .el-input-number__increase [class*=el-icon],
.add-info .add-form /deep/ .el-input-number.is-controls-right .el-input-number__decrease [class*=el-icon]
{
  transform: scale(1.2);
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:3rem;

}



.form-button>div {
  cursor: pointer;
  width: 15.375rem;
  height: 2.5rem;
  display: block;
  line-height: 2.5rem;
  background: #FFFFFF;
  border: 0.78125px solid #CCCCCC;
  border-radius: 1.95312rem;


  font-style: normal;
  font-weight: 700;
  font-size: 1.09375rem;
  text-align: center;

  color: #CCCCCC;

  margin-right: 1.157rem;
}
</style>
